@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    /* allow for padding to not influence container max-width */
    @apply sm:box-content sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px];
  }
}

/* hide captcha banner */
.captcha_root {
  display: none;
}

/* hide default cookie settings link */
.cc-revoke {
  visibility: hidden !important;
}

.widget-footer {
  display: none;
}
